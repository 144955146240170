@import 'styles/utils';

.wrapper {
  padding: 60px 0 50px 0;
  @include breakpoint('sm') {
    padding: 100px 0;
  }
}

.title {
  display: block;
  color: $white;
  padding-bottom: 30px;
  @include breakpoint('sm') {
    padding-bottom: 50px;
  }
  @include breakpoint('xxl') {
    padding-bottom: 30px;
  }
}

.newsWrapper.newsWrapper {
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
  @include breakpoint('sm') {
    width: 49%;
  }
  @include breakpoint('xl') {
    width: 32%;
  }
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
  display: block;
  z-index: 0;
}

.newsTextWrapper {
  padding: 13px 0 32px 0;
  @include breakpoint('sm') {
    padding: 13px 20px 50px 20px;
  }
  @include breakpoint('md') {
    padding: 15px 20px 50px 20px;
  }
}

.ctaText.ctaText {
  color: $starcar-yellow;
  display: block;
  line-height: 24px;
  margin-right: 8px;
}

.link {
  color: inherit;
  text-decoration: none;
  display: block;
  width: fit-content;
}

.icon {
  width: 24px;
  height: 24px;
  color: $starcar-yellow;
}

.ctaWrapper {
  margin-top: 5px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  width: fit-content;
  &:hover {
    transform: translateX(10px);
  }
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.4));
  border-radius: 4px;
  z-index: 1;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.button {
  width: 100%;
  a {
    color: inherit; /* Inherit the color from the button */
    text-decoration: none; /* Remove underline */
  }
  @include breakpoint('sm') {
    width: auto;
  }
}

.newsArticleDescription.newsArticleDescription {
  color: $white;
  overflow-wrap: break-word;
  line-height: 24px;
}

.newsArticleTitle.newsArticleTitle {
  position: absolute;
  display: block;
  line-height: 32px;
  color: $starcar-yellow;
  bottom: 10px;
  left: 20px;
  z-index: 1;
}
